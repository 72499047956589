import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import links from 'links'

import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'

import HolidayBaseBanner from '../HolidayBaseBanner/HolidayBaseBanner'

import messages from './messages'


type BlackFridayBannerProps = {
  position?: number
}

const GsPostCutoffBanner: React.FC<BlackFridayBannerProps> = ({ position }) => {
  const { isMobile } = useDevice()

  return (
    <HolidayBaseBanner
      position={position}
      to={links.gifts}
      withBgImage
      data-testid="blackFridayBanner"
    >
      <div className={cx('flex h-full items-center justify-center gap-16 px-16', isMobile && 'w-full')}>
        <Text
          className="flex-1 text-left text-white"
          message={messages.title}
          style="h8"
          html
        />
        <Button className="px-24" size={38} noPadding title={messages.button} />
      </div>
    </HolidayBaseBanner>
  )
}

export default GsPostCutoffBanner
