export default {
  text: {
    desktop: {

      en: 'Last chance to shop deluxe limited edition scents',
    },
    mobile: {
      en: 'Last chance to shop The<br />Limited Drop Vault',
    },
  },
  shopVault: {
    en: 'Shop The Vault',
  },
}
