import { usePathname } from 'router'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import links from 'links'

import { useExpiration } from 'hooks'
import useFt from 'hooks/useFt'
import { constants } from 'helpers'

import { useLimitedDropSale } from 'modules/limitedDrops'
import { usePrivateSale } from 'modules/products'
import { useCandleSubscription, useDriftSubscription, useSubscription } from 'modules/subscription'
import { useUser } from 'modules/user'


const normalizePagePaths = (pagePaths: string[]) => {
  return pagePaths
    .map((link) => link.replace('(.*)', '').replace(':slug', ''))
    .join('|')
}

const productPagePaths = normalizePagePaths([
  links.productGeneral,
  links.productCandle,
  links.productExtra,
  links.productMakeup,
  links.productPerfume,
  links.productPerfumeLanding,
  links.productPersonalCare,
  links.productSkincare,
  links.productWellness,
  links.productDiffuser,
  links.productAccessory,
])

const vipSalePagePaths = normalizePagePaths([
  links.shop.travelSize.root,
  links.shop.samples.root,
  links.shop.scentbirdCases,
])

const limitedDropPagePaths = normalizePagePaths([
  links.currentLimitedDrop,
  links.limitedDrop,
  links.limitedDropProduct,
])

const productPagePathRegExp = new RegExp(`^(${productPagePaths})`)

const vipSalePagePathRegExp = new RegExp(`^(${vipSalePagePaths})`)

const limitedDropPagePathRegExp = new RegExp(`^(${limitedDropPagePaths})`)

const useBanner = () => {
  const pathname = usePathname()

  const { isLoggedIn, isFetching: isUserFetching } = useUser()
  const { subscription, isFetching: isSubscriptionFetching } = useSubscription()
  const { privateSale, isFetching: isPrivateSaleFetching } = usePrivateSale()
  const { candleSubscription, isFetching: isCandleSubscriptionFetching } = useCandleSubscription()
  const { driftSubscription } = useDriftSubscription()

  const { data: limitedDropData, isFetching: isLimitedDropFetching } = useLimitedDropSale()

  const isGiftHubEnabled = useFt(constants.features.giftHub)
  const isHolidayMothersDayEnabled = useFt(constants.features.holidayMothersDay)
  const isHolidayFathersDayEnabled = useFt(constants.features.holidayFathersDay)
  const isGiftHubHeaderBannerEnabled = useFeatureIsOn(constants.features.giftHubHeaderBanner)
  const isHeaderBannerVisibleOnGiftHubEnabled = useFeatureIsOn(constants.features.headerBannerVisibleOnGiftHub)

  const holidayBannerDiscountValue = useFeatureValue(constants.features.holidayBanner, 0)
  const isBlackFridayBannerEnabled = useFt(constants.features.blackFridayTopHomePageBanner)
  const isCyberMondayBannerEnabled = useFt(constants.features.cyberMondayTopHomePageBanner)

  const isDriftSubscriptionEnabled = useFt(constants.features.driftSubscription)
  const isDriftSubscriptionLaunchPromoEnabled = useFt(constants.features.driftSubscriptionLaunchPromo)

  const isBfcmNotificationEnabled = useFeatureIsOn(constants.features.bfcmNotification)
  const isGiftSubscriptionNotificationEnabled = useFeatureIsOn(constants.features.giftSubscriptionNotification)

  const isGwpTopBarVisible = useFeatureIsOn(constants.features.gwpTopBar)
  const isMansGroomingTopBarVisible = useFeatureIsOn(constants.features.mansGroomingTopBar)
  const isTheSummerEditEnabled = useFeatureIsOn(constants.features.theSummerEdit)
  const isVipSubscriberSaleEnabled = useFeatureIsOn(constants.features.vipSubscriberSale)
  const isCandleSaleEnabled = useFeatureIsOn(constants.features.candleSale)
  const isGsPostCutoffEntryPointsEnabled = useFeatureIsOn(constants.features.gsPostCutoffEntryPoints)

  const isFetching = isUserFetching || isSubscriptionFetching || isPrivateSaleFetching || isCandleSubscriptionFetching || isLimitedDropFetching

  const isSubscribeBannerVisible = (
    subscription?.hasNeverSubscribed
    && pathname !== links.subscription.main
    && !productPagePathRegExp.test(pathname)
  )

  // show banner only when subscription is in undeliverable status
  const isUndeliverableBannerVisible = subscription?.isUndeliverable

  const isCardUpdateBannerVisible = subscription?.isUnpaid

  const isTossInBannerVisible = (
    isLoggedIn
    && pathname !== links.tossIn
    && subscription?.canApplyTossInOffer
  )

  const holidayBannerDiscount = pathname !== links.shop.holiday ? holidayBannerDiscountValue : 0

  const isBfcmBannerVisible = (
    isBfcmNotificationEnabled
    && pathname === links.shop.holiday
  )

  const isGiftSubscriptionBannerVisible = (
    isGiftSubscriptionNotificationEnabled
    && pathname === links.giftSubscription
  )

  const isHolidayBannerVisible = (
    isGiftHubEnabled
    // This logic is little strange, but we needed to show the holiday banner only on the Gifts page for a limited period of time
    // TODO: Remove it after valentine day — added on 25–01–2024 by algeas
    && (pathname !== links.gifts ? isGiftHubHeaderBannerEnabled : isHeaderBannerVisibleOnGiftHubEnabled)
    && !isHolidayMothersDayEnabled
    && !isHolidayFathersDayEnabled
    && !isBfcmBannerVisible
    && !isGiftSubscriptionBannerVisible
  )

  const isHolidayMothersDayVisible = (
    isGiftHubEnabled
    && isGiftHubHeaderBannerEnabled
    && isHolidayMothersDayEnabled
    && pathname !== links.gifts
  )

  const isHolidayFathersDayVisible = (
    isGiftHubEnabled
    && isGiftHubHeaderBannerEnabled
    && isHolidayFathersDayEnabled
    && pathname !== links.gifts
  )

  const isBlackFridayBannerVisible = (
    subscription?.hasNeverSubscribed
    && isBlackFridayBannerEnabled
    && (
      pathname === links.home || pathname === links.homeMen
    )
  )

  const isCyberMondayBannerVisible = (
    subscription?.hasNeverSubscribed
    && isCyberMondayBannerEnabled
    && (
      pathname === links.home || pathname === links.homeMen
    )
  )

  const isPrivateSaleExpired = useExpiration({ timeLeft: privateSale?.timeLeft })
  const isPrivateSaleBannerVisible = (
    isLoggedIn
    && pathname !== links.privateSale
    && Boolean(privateSale)
    && !isPrivateSaleExpired
  )

  const isUnskipBannerVisible = (
    isLoggedIn
    && subscription?.isOnHold
    && Boolean(subscription?.activeHold)
  )

  const isCandleSubscriptionBannerVisible = (
    candleSubscription?.isEnabled
    && !candleSubscription?.isSelected
    && pathname !== links.candleSubscription
    && pathname !== links.featuredCandle
  )

  const isDriftSubscriptionVisible = (
    isDriftSubscriptionEnabled
    && driftSubscription?.isEnabled
    && !driftSubscription?.isSelected
    && pathname !== links.driftSubscription
  )
  const isDriftSubscriptionHasLaunchPriority = isDriftSubscriptionLaunchPromoEnabled

  const isResubscribeBannerVisible = Boolean(subscription?.isCancelled)

  const isTheSummerEditTopBarVisible = (
    isTheSummerEditEnabled
    && pathname !== links.shop.theEdit
  )

  const isVipSubscriberSaleTopBarVisible = (
    isVipSubscriberSaleEnabled
    && !vipSalePagePathRegExp.test(pathname)
  )

  const isCandleSaleTopBarVisible = (
    isCandleSaleEnabled
    && pathname !== links.shop.candlesHomeScents
  )

  const isLimitedDropBannerVisible = (
    limitedDropData?.entryPointsUsageMap?.topBar
    && !limitedDropPagePathRegExp.test(pathname)
  )

  const isGsPostCutoffBannerVisible = isGsPostCutoffEntryPointsEnabled

  return {
    isFetching,
    isCardUpdateBannerVisible,
    isSubscribeBannerVisible,
    isUndeliverableBannerVisible,
    isTossInBannerVisible,
    isPrivateSaleBannerVisible,
    isUnskipBannerVisible,
    isCandleSubscriptionBannerVisible,
    isHolidayBannerVisible,
    holidayBannerDiscount,
    isHolidayMothersDayVisible,
    isHolidayFathersDayVisible,
    isBlackFridayBannerVisible,
    isCyberMondayBannerVisible,
    isResubscribeBannerVisible,
    isDriftSubscriptionVisible,
    isDriftSubscriptionHasLaunchPriority,
    isBfcmBannerVisible,
    isGiftSubscriptionBannerVisible,
    isGwpTopBarVisible,
    isMansGroomingTopBarVisible,
    isTheSummerEditTopBarVisible,
    isVipSubscriberSaleTopBarVisible,
    isCandleSaleTopBarVisible,
    isLimitedDropBannerVisible,
    isGsPostCutoffBannerVisible,
  }
}


export default useBanner
